.providers {
  display: flex;
  width: 940px;
  height: 400px;
  overflow-y: hidden;
  position: relative;
}

#map {
  flex: 1;
}

.provider-list {
  flex: 0 0 350px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  scrollbar-color: #ddd #fafafa;
  scrollbar-width: thin;

  > div {
    display: flex;
    padding: 12px;
    border-bottom: 1px solid rgb(238, 238, 238);
    cursor: pointer;
  }

  img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    margin-right: 8px;
  }

  .provider-info {
    display: flex;
    flex-direction: column;
    padding: 4px 0;


    .name {
      font-weight: bold;
      margin-bottom: 4px;
    }

    .location, .activities {
      color: #757575;
      font-size: 14px;
    }

    .location {
      flex: 1;
    }
  }
}

.mb-8 {
  margin-bottom: 24px;
}

.text-lg {
  font-size: 18px;
}

.font-bold {
    font-weight: bold;
}

.mb-2 {
    margin-bottom: 8px;
}

.text-sm  {
  font-size: 14px;
}

.text-200 {
  color: #222222;
}

.text-700 {
  color: #616161;
}

.text-400 {
  color: #BDBDBD;
}

.text-500 {
  color: #9E9E9E;
}

.text-600 {
  color: #757575;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.mr-2 {
  margin-right: 8px;
}

.amenities {
  background-color: #FAFAFA;
  padding: 4px 12px;
  border-radius: 18px;
  border: 1px solid #EEEEEE;
  height: 32px;
}

.provider-preview {
  background: white;
  position: absolute;
  left: 100px;
  top: 260px;
  width: 400px;
}

.p-4 {
  padding: 16px;
}

.mr-4 {
  margin-right: 16px;
}

p {
  margin-top: 0;
}
